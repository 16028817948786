import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../NavBar/AuthNavbar'
import Alert from '../Alerts/Alert'
import { clearStorage, getStorage } from '../../shared/LoacalStorage'

export class Verification extends Component {
  constructor(props) {
    super(props)
    this.state = { emailAddress: '', otp: '' }
    this.state = { displayAlert: false, AlertMessage: '' }
  }
  componentDidMount() {
    this.setState({ emailAddress: getStorage('cache') })
  }
  handleSubmission = async (event) => {
    this.setState({ displayAlert: false })
    const { otp, emailAddress } = this.state
    event.preventDefault()
    const body = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailAddress: emailAddress,
        otp: otp,
      }),
    }

    const response = await fetch(
      'https://youco2api.azurewebsites.net/api/Authentication/verify',
      body,
    )
    const data = await response.json()
    // when response is sucess
    if (data.success === true) {
      clearStorage()
      window.location.href = '/Auth'
    } else if (data.success === false) {
      this.setState({ displayAlert: true, AlertMessage: data.errors[0] })
    }
  }
  resendOTP = async (event) => {
    event.preventDefault()
    this.setState({ displayAlert: false })
    const { emailAddress } = this.state
    const body = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        emailAddress: emailAddress,
      }),
    }

    const response = await fetch(
      'https://youco2api.azurewebsites.net/api/Authentication/resend',
      body,
    )
    const data = await response.json()
    // when response is sucess
    if (data.success === false) {
      this.setState({ displayAlert: true, AlertMessage: data.errors[0] })
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  renderAlert = () => {
    if (this.state.displayAlert) {
      return (
        <>
          <Alert message={this.state.AlertMessage} />
        </>
      )
    }
  }
  render() {
    return (
      <>
        <Navbar transparent />
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          ></div>
          <div className="container mx-auto px-5 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-blueGray-500 text-lg font-bold">
                        Verify Email Address
                      </h6>
                    </div>
                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                      <small>
                        A four digit verification code has been sent to your
                        registered email address.
                      </small>
                    </div>
                    <form onSubmit={this.handleSubmission}>
                      {this.renderAlert()}
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Verification Code
                        </label>
                        <input
                          required
                          type="text"
                          name="otp"
                          onChange={this.handleChange}
                          className=" border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="OTP"
                          autoComplete="on"
                        />
                      </div>
                      <div className="text-center mt-6 flex flex-wrap ">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Verify
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2">
                    <a
                      href="#validateResend"
                      onClick={this.resendOTP}
                      className="text-blueGray-200"
                    >
                      <small>Resend OTP?</small>
                    </a>
                  </div>
                  <div className="w-1/2 text-right">
                    <Link to="/auth" className="text-blueGray-200">
                      <strong>Sign in instead?</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
