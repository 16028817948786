import React from 'react'

export default function PrivacyPolicyPage() {
  return (
    <>
      <nobr><nowrap>
        <div className="pos" id="_100:102" style={{ top: 102, left: 100 }}>
          <span id="_21.5" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '21.5px', color: '#000000' }}>
            Privacy Policy<span id="_14.8" style={{ fontWeight: 'normal', fontSize: '14.8px' }}> .</span></span>
        </div>
        <div className="pos" id="_100:151" style={{ top: 151, left: 100 }}>
          <span id="_17.5" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.5px', color: '#000000' }}>
            About this Policy</span>
        </div>
        <div className="pos" id="_100:194" style={{ top: 194, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            This Privacy Policy applies to personal data collected through our website at </span>
        </div>
        <div className="pos" id="_100:215" style={{ top: 215, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#1054cc' }}>
            <a href="http://www.yourco2.co.uk/">
              www.yourco2.co.uk  </a>
          </span><a href="http://www.yourco2.co.uk/">
          </a>
          and which is obtained in the course of providing services via the website to
        </div><a href="http://www.yourco2.co.uk/">
        </a><div className="pos" id="_100:235" style={{ top: 235, left: 100 }}><a href="http://www.yourco2.co.uk/">
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
          </span></a>the users of the website.
        </div>
        <div className="pos" id="_100:275" style={{ top: 275, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            We take your privacy very seriously.  We ask that you read this Privacy Policy carefully as it </span>
        </div>
        <div className="pos" id="_100:295" style={{ top: 295, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            contains important information about our processing and your rights.</span>
        </div>
        <div className="pos" id="_100:337" style={{ top: 337, left: 100 }}>
          <span id="_18.8" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '18.8px', color: '#000000' }}>
            How to contact us</span>
        </div>
        <div className="pos" id="_100:382" style={{ top: 382, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            If you have any questions about this Privacy Policy, how we handle your personal data, or want </span>
        </div>
        <div className="pos" id="_100:402" style={{ top: 402, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            to exercise any of your rights, please contact us at <u>C</u><u>o</u><u>n</u><u>t</u><u>a</u><u>c</u><u>t</u><u>@</u><u>y</u><u>o</u><u>u</u><u>r</u><u>c</u><u>o</u><u>2</u><u>.</u><u>c</u><u>o</u><u>.</u><u>u</u><u>k</u></span>
        </div>
        <div className="pos" id="_100:443" style={{ top: 443, left: 100 }}>
          <span id="_17.5" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.5px', color: '#000000' }}>
            About us</span>
        </div>
        <div className="pos" id="_100:486" style={{ top: 486, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            This Privacy Policy is provided by Your Co2 which is a trading name of Abblab Ltd. ('we' or 'us'). </span>
        </div>
        <div className="pos" id="_100:507" style={{ top: 507, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            We are a 'controller' for the purposes of the UK Data Protection Act 2018 and UK General Data </span>
        </div>
        <div className="pos" id="_100:527" style={{ top: 527, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            Protection Regulation (EU) 2016/679 ("Data Protection Laws"). </span>
        </div>
        <div className="pos" id="_100:568" style={{ top: 568, left: 100 }}>
          <span id="_17.5" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.5px', color: '#000000' }}>
            Changes to the Privacy Policy</span>
        </div>
        <div className="pos" id="_100:611" style={{ top: 611, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            We may change this Privacy Policy from time to time. You should check this Privacy Policy </span>
        </div>
        <div className="pos" id="_100:631" style={{ top: 631, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            occasionally to ensure you are aware of the most recent version that will apply each time you </span>
        </div>
        <div className="pos" id="_100:652" style={{ top: 652, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            access this website.</span>
        </div>
        <div className="pos" id="_100:693" style={{ top: 693, left: 100 }}>
          <span id="_17.5" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.5px', color: '#000000' }}>
            What personal data do we collect<span id="_14.8" style={{ fontSize: '14.8px' }}> </span></span>
        </div>
        <div className="pos" id="_100:736" style={{ top: 736, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            We have set out below the personal data that we collect and how we collect it:</span>
        </div>
        <div className="pos" id="_100:777" style={{ top: 777, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            Direct interactions: your name, email address, contact information and other details you provide </span>
        </div>
        <div className="pos" id="_100:797" style={{ top: 797, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            when you generate a report (Identity Data).</span>
        </div>
        <div className="pos" id="_100:837" style={{ top: 837, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            From industry sources: when you provide us with your information to generate a report, we pull </span>
        </div>
        <div className="pos" id="_100:857" style={{ top: 857, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            information from relevant industry databases to understand your carbon footprint in order to </span>
        </div>
        <div className="pos" id="_100:878" style={{ top: 878, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            provide our services. We continue to update this data on an ongoing basis until or unless you </span>
        </div>
        <div className="pos" id="_100:898" style={{ top: 898, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            ask us not to or terminate the service (Identity Data).</span>
        </div>
        <div className="pos" id="_100:938" style={{ top: 938, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            Cookies: we may use cookies on our website which collect data such as your IP address, </span>
        </div>
        <div className="pos" id="_100:958" style={{ top: 958, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            device type, your login information, browser type and version, time zone setting, browser plug-in </span>
        </div>
        <div className="pos" id="_100:979" style={{ top: 979, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            types and versions, screen resolution, operating system and platform; and information about </span>
        </div>
        <div className="pos" id="_100:1201" style={{ top: 1201, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            your visit, including the full Uniform Resource Locators, clickstream to, through and from our site </span>
        </div>
        <div className="pos" id="_100:1221" style={{ top: 1221, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            (including date and time), page response times, download errors, length of visits to certain </span>
        </div>
        <div className="pos" id="_100:1241" style={{ top: 1241, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            pages, page interaction information (such as scrolling, clicks, and mouse-overs) and methods </span>
        </div>
        <div className="pos" id="_100:1261" style={{ top: 1261, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            used to browse away from the page (Technical Data).</span>
        </div>
        <div className="pos" id="_100:1302" style={{ top: 1302, left: 100 }}>
          <span id="_17.7" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.7px', color: '#000000' }}>
            Legal basis for processing your data</span>
        </div>
        <div className="pos" id="_100:1346" style={{ top: 1346, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            We are allowed to process your personal data for the following reasons and on the following </span>
        </div>
        <div className="pos" id="_100:1366" style={{ top: 1366, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            legal bases: </span>
        </div>
        <div className="pos" id="_100:1406" style={{ top: 1406, left: 100 }}>
          <span id="_15.0" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Legitimate Interests<span style={{ fontWeight: 'normal' }}> </span></span>
        </div>
        <div className="pos" id="_100:1427" style={{ top: 1427, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            We are permitted to process your personal data if it is based on our ‘legitimate interests’ i.e. we </span>
        </div>
        <div className="pos" id="_100:1447" style={{ top: 1447, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            have good, sensible, practical reasons for processing your personal data which is in our </span>
        </div>
        <div className="pos" id="_100:1467" style={{ top: 1467, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            interests. To do so, we have considered the impact on your interests and rights, and have </span>
        </div>
        <div className="pos" id="_100:1487" style={{ top: 1487, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            placed appropriate safeguards to ensure that the intrusion on your privacy is reduced as much </span>
        </div>
        <div className="pos" id="_100:1507" style={{ top: 1507, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            as possible.  The table in the section "Why do we process your personal data" explains the </span>
        </div>
        <div className="pos" id="_100:1528" style={{ top: 1528, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            personal data processed on this basis. You can object to processing that we carry out on the </span>
        </div>
        <div className="pos" id="_100:1548" style={{ top: 1548, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            grounds of legitimate interests. See the section headed "What rights and control do I have" to </span>
        </div>
        <div className="pos" id="_100:1568" style={{ top: 1568, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            find out how.</span>
        </div>
        <div className="pos" id="_100:1608" style={{ top: 1608, left: 100 }}>
          <span id="_15.0" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Contract</span>
        </div>
        <div className="pos" id="_100:1629" style={{ top: 1629, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            It is necessary for our performance of the contract you have agreed to enter with us or a </span>
        </div>
        <div className="pos" id="_100:1649" style={{ top: 1649, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Partner. If you do not provide your personal data to us, we will not be able to carry out our </span>
        </div>
        <div className="pos" id="_100:1669" style={{ top: 1669, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            obligations under the terms of your contract. For example, we require your personal data to </span>
        </div>
        <div className="pos" id="_100:1689" style={{ top: 1689, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            locate the correct carbon data for you in order that we can provide tailored advice; plus require </span>
        </div>
        <div className="pos" id="_100:1709" style={{ top: 1709, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            your contact details to keep you informed.</span>
        </div>
        <div className="pos" id="_100:1750" style={{ top: 1750, left: 100 }}>
          <span id="_15.0" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Consent<span style={{ fontWeight: 'normal' }}></span></span>
        </div>
        <div className="pos" id="_100:1770" style={{ top: 1770, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Sometimes we want to use your personal data in a way that is entirely optional for you, such as </span>
        </div>
        <div className="pos" id="_100:1790" style={{ top: 1790, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            Cookies to ensure our website is optimised for your use. On these occasions, we will ask for </span>
        </div>
        <div className="pos" id="_100:1810" style={{ top: 1810, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            your consent to use your information. You can withdraw this consent at any time although we </span>
        </div>
        <div className="pos" id="_100:1831" style={{ top: 1831, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            will not then be able to provide the services which needed your consent.</span>
        </div>
        <div className="pos" id="_100:1891" style={{ top: 1891, left: 100 }}>
          <span id="_17.7" style={{ fontWeight: 'bold', fontFamily: 'Arial', fontSize: '17.7px', color: '#000000' }}>
            What rights and control do you have?</span>
        </div>
        <div className="pos" id="_100:1931" style={{ top: 1931, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            As our business is registered in the UK, you have rights over the data about that we collect. You </span>
        </div>
        <div className="pos" id="_100:1948" style={{ top: 1948, left: 100 }}>
          <span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px', color: '#000000' }}>
            can:</span>
        </div>
        <div className="pos" id="_158:1981" style={{ top: 1981, left: 158 }}>
          <span id="_16.4" style={{ fontFamily: 'Times New Roman', fontSize: '16.4px', color: '#000000' }}>
            &nbsp;<span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px' }}> Request for a copy of data we have collected about you</span></span>
        </div>
        <div className="pos" id="_158:1998" style={{ top: 1998, left: 158 }}>
          <span id="_16.4" style={{ fontFamily: 'Times New Roman', fontSize: '16.4px', color: '#000000' }}>
            &nbsp;<span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px' }}> Request us to update that data</span></span>
        </div>
        <div className="pos" id="_158:2016" style={{ top: 2016, left: 158 }}>
          <span id="_16.4" style={{ fontFamily: 'Times New Roman', fontSize: '16.4px', color: '#000000' }}>
            &nbsp;<span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px' }}> Request us to delete it</span></span>
        </div>
        <div className="pos" id="_158:2033" style={{ top: 2033, left: 158 }}>
          <span id="_16.4" style={{ fontFamily: 'Times New Roman', fontSize: '16.4px', color: '#000000' }}>
            &nbsp;<span id="_15.0" style={{ fontFamily: 'Arial', fontSize: '15.0px' }}> Request us to stop using it</span></span>
        </div>
        <div className="pos" id="_100:2301" style={{ top: 2301, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            If you ask for a copy of data we have about you, we have to provide it to you free of charge. You </span>
        </div>
        <div className="pos" id="_100:2318" style={{ top: 2318, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            can ask us to send it directly to a third party. We have to provide it in a commonly-used </span>
        </div>
        <div className="pos" id="_100:2336" style={{ top: 2336, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            machine-readable format.</span>
        </div>
        <div className="pos" id="_100:2371" style={{ top: 2371, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            To do these things, you can contact <u>d</u><u>a</u><u>t</u><u>a</u><u>c</u><u>o</u><u>n</u><u>t</u><u>r</u><u>o</u><u>l</u><u>l</u><u>e</u><u>r</u><u>@</u><u>t</u><u>h</u><u>e</u><u>o</u><u>d</u><u>i</u><u>.</u><u>o</u><u>r</u><u>g</u>.</span>
        </div>
        <div className="pos" id="_100:2406" style={{ top: 2406, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            Please tell us if you have concerns about how we collect, use or share data about you. You can </span>
        </div>
        <div className="pos" id="_100:2424" style={{ top: 2424, left: 100 }}>
          <span id="_14.8" style={{ fontFamily: 'Arial', fontSize: '14.8px', color: '#000000' }}>
            also raise your concerns with the UK Information Commissioner’s Office.</span>
        </div>
      </nowrap></nobr>
    </>
  )
}
