import IMG_LOGO_GREENHOUSE from '../../assets/img/Test Logo.Jpg'
import IMG_LOGO_SECR from '../../assets/img/TestLogo1.jpg'

export default function StaticLogos() {
    return (
        <>
            <div className="container mx-auto ">
                <div className="justify-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-2  -mt-1">
                        <div className="flex flex-wrap">

                            <div className="w-full md:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-2 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full rounded-sm"
                                        src={IMG_LOGO_GREENHOUSE}
                                    />
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 px-4">
                                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-2 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img
                                        alt="..."
                                        className="align-middle border-none max-w-full rounded-sm"
                                        src={IMG_LOGO_SECR}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}