import React from 'react'
import Chart from 'chart.js'

export default function CarbonGraphIndex() {
  React.useEffect(() => {
    let labelsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December	"]
    // let region1 = [6027, 2230, 4483, 4648, 2862, 1638, 8926, 4216, 189, 118.164, 711, 1829]
    let region2 = [2823, 1477, 2427, 3684, 2568, 1354, 3267, 1267, 399, 878, 1092, 1615]
    // let region3 = [4869, 2829, 4793, 4697, 5017, 4550, 7796, 6685, 6384, 4019, 3311, 3025]
    // let region4 = [3642, 2434, 3562, 4337, 4968, 3384, 5725, 4071, 5381, 2716, 2625, 2643]
    // let region5 = [14064, 10879, 10269, 11536, 11362, 11330, 13525, 11279, 11256, 8028, 8428, 7518]
    // let region6 = [7450, 4902, 6739, 7458, 7111, 5189, 9340, 8136, 8351, 4404, 6156, 5635]
    let region7 = [16789, 13741, 13984, 14213, 14534, 15219, 17819, 15444, 15015, 14877, 14872, 12669]
    // let region8 = [12160, 8187, 9141, 10671, 10114, 10015, 15362, 11675, 11878, 8743, 9151, 8676]
    // let region9 = [19842, 14191, 12921, 14224, 13261, 14172, 20230, 14851, 15557, 12021, 14682, 13371]
    // let region10 = [19842, 14191, 12921, 14224, 13261, 14172, 20230, 14851, 15557, 12021, 14682, 13371]
    // let region11 = [15032, 11329, 10251, 4460, 2245, 5584, 7506, 6872, 9958, 9784, 9429, 8221]
    // let region12 = [15990, 12047, 11549, 11884.2885, 10469, 10491, 9695, 9821, 11456, 11144, 12629, 11710]
    // let region13 = [9546, 7129, 7388, 7798, 5396, 4432, 4858, 4381, 6188, 7975, 10059, 8293]
    // let region14 = [9227, 7324, 7364, 7796, 5296, 4027, 4787, 4379, 6008, 8350, 10816, 8700]
    let staticFactorList = [10339, 9064, 9416, 9250, 9832, 10038, 11064, 10357, 9397, 10245, 9920, 8207]
    let yearBuildingWasCompleted = '2021'

    var config = {
      type: 'line',
      data: {
        labels: labelsList,
        datasets: [
          {
            label: 'Grid average intensity',//'Static carbon calculation (BEIS)',
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            data: staticFactorList,
            borderDash: [5, 2]
          },
          // {
          //   label: 'North Scotland',
          //   backgroundColor: '#4c51bf',
          //   borderColor: '#4c51bf',
          //   data: region1,
          //   fill: false,
          // },
          {
            label: 'Lowest intensity region',// 'South Scotland',
            backgroundColor: '#e7d068',
            borderColor: '#e7d068',
            data: region2,
            fill: false,
          },
          // {
          //   label: 'North West England',
          //   backgroundColor: '#92776c',
          //   borderColor: '#92776c',
          //   data: region3,
          //   fill: false,
          // },
          // {
          //   label: 'North East England',
          //   backgroundColor: '#6a5246',
          //   borderColor: '#6a5246',
          //   data: region4,
          //   fill: false,
          // },
          // {
          //   label: 'Yorkshire',
          //   backgroundColor: '#825d05',
          //   borderColor: '#825d05',
          //   data: region5,
          //   fill: false,
          // },
          // {
          //   label: 'North Wales',
          //   backgroundColor: '#bec339',
          //   borderColor: '#bec339',
          //   data: region6,
          //   fill: false,
          // },
          {
            label: 'Higest intensity region',//'South Wales',
            backgroundColor: '#f4bbcc',
            borderColor: '#f4bbcc',
            data: region7,
            fill: false,
          },
          // {
          //   label: 'West Midlands',
          //   backgroundColor: '#71acaf',
          //   borderColor: '#71acaf',
          //   data: region8,
          //   fill: false,
          // }, {
          //   label: 'East Midlands',
          //   backgroundColor: '#083c3a',
          //   borderColor: '#083c3a',
          //   data: region9,
          //   fill: false,
          // }, {
          //   label: 'East England',
          //   backgroundColor: '#800020',
          //   borderColor: '#800020',
          //   data: region10,
          //   fill: false,
          // }, {
          //   label: 'South West England',
          //   backgroundColor: '#844012',
          //   borderColor: '#844012',
          //   data: region11,
          //   fill: false,
          // }, {
          //   label: 'South England',
          //   backgroundColor: '#ff9738',
          //   borderColor: '#ff9738',
          //   data: region12,
          //   fill: false,
          // }, {
          //   label: 'London',
          //   backgroundColor: '#dad252',
          //   borderColor: '#dad252',
          //   data: region13,
          //   fill: false,
          // }, {
          //   label: 'South East England',
          //   backgroundColor: '#787878',
          //   borderColor: '#787878',
          //   data: region14,
          //   fill: false,
          // },

        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: 'Sales Charts',
          fontColor: 'white',
        },
        legend: {
          labels: {
            fontColor: 'white',
          },
          align: 'end',
          position: 'bottom',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: yearBuildingWasCompleted,
                fontColor: 'white',
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: 'rgba(33, 37, 41, 0.3)',
                zeroLineColor: 'rgba(0, 0, 0, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
                beginAtZero: true,
                userCallback: function (value, index, values) {
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(',');
                  return value;
                }
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'KgCo2e',
                fontColor: 'white',
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: 'rgba(255, 255, 255, 0.15)',
                zeroLineColor: 'rgba(33, 37, 41, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    }
    var ctx = document.getElementById('line-chart').getContext('2d')
    window.myLine = new Chart(ctx, config)
  }, [])
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Overview
              </h6>
              <h2 className="text-white text-xl font-semibold">
                Carbon footprint of a typical office located in different regions
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  )
}
