import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function CardPurchaseReport() {
    const navigate = useNavigate()
    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-1">
                <div className="px-6">
                    <div className="text-center mt-5">
                        <h3 className="text-5xl font-bold leading-normal mb-2 text-blueGray-600 mb-2">
                            Add additional report
                        </h3>
                    </div>
                    <div className="mb-8 text-blueGray-600 text-xl font-bold  mt-5"> If you wish to add reports for another year or another building then please do so below.</div>

                </div>
                <div className="px-3 text-center">
                    <button className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-lg px-6 py-5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={() => navigate('/ContactUs')}
                    >
                        Add additional report
                    </button>
                </div>
                <br />
            </div>
        </>
    )
}
