import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../NavBar/AuthNavbar'
import Alert from '../Alerts/Alert'
import { setStorage } from '../../shared/LoacalStorage'

export class Register extends React.Component {
  openInNewTab = (e, url) => {
    e.preventDefault()
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  constructor(props) {
    super(props)
    this.state = {
      emailAddress: '',
      firstname: '',
      surName: '',
      password: '',
      confirmPassword: '',
      isTermsAccepted: '',
      isRegistering: true,
    }
    this.state = { displayAlert: false, AlertMessage: '' }
  }
  componentDidMount() {
    this.setState({ isRegistering: false })
  }
  validateTerms = (isTermsAccepted) => {
    console.log(isTermsAccepted)
    if (!isTermsAccepted) {
      this.setState({ displayAlert: true, AlertMessage: "Please accept terms and conditions" })
      return false;
    }
    else return true;
  }
  handleSubmission = async (event) => {
    this.setState({ displayAlert: false, isRegistering: true })
    const {
      emailAddress,
      firstname,
      surName,
      confirmPassword,
      password,
      isTermsAccepted
    } = this.state
    event.preventDefault()
    if (this.validateTerms(isTermsAccepted)) {
      const body = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          emailAddress: emailAddress,
          fullname: firstname + ' ' + surName,
          password: password,
          confirmPassword: confirmPassword,
        }),
      }
      const response = await fetch(
        'https://youco2api.azurewebsites.net/api/Authentication/register',
        body,
      )
      const data = await response.json()
      // when response is sucess
      if (data.success === true) {
        // navigate to login page
        setStorage('cache', emailAddress)
        window.location.href = '/auth/verification'
      } else if (data.success === false) {
        this.setState({ displayAlert: true, AlertMessage: data.errors[0] })
      }
    }
    this.setState({ isRegistering: false })
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleCheck = (event) => {
    this.setState({
      isTermsAccepted: event.target.checked,
    })
  }
  renderAlert = () => {
    if (this.state.displayAlert) {
      return (
        <>
          <Alert message={this.state.AlertMessage} />
        </>
      )
    }
  }

  render() {
    return (
      <>
        <Navbar transparent />
        <section className="relative w-full h-full py-20 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="rounded-t mb-0 px-6 py-3"></div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                      <small>Sign up with credentials</small>
                    </div>
                    <form onSubmit={this.handleSubmission}>
                      {this.renderAlert()}
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          required
                          name="firstname"
                          value={this.state.firstname}
                          onChange={this.handleChange}
                          autoComplete="on"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder=" First Name"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Surname
                        </label>
                        <input
                          required
                          name="surName"
                          value={this.state.surName}
                          onChange={this.handleChange}
                          autoComplete="on"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Surname"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Email
                        </label>
                        <input
                          required
                          type="email"
                          name="emailAddress"
                          value={this.state.emailAddress}
                          onChange={this.handleChange}
                          autoComplete="on"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Email"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Password
                        </label>
                        <input
                          required
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          autoComplete="on"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Confirm Password
                        </label>
                        <input
                          required
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          autoComplete="on"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Password"
                        />
                      </div>
                      <div>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            id="customCheckLogin"
                            type="checkbox"
                            name='isTermsAccepted'
                            value={this.state.isTermsAccepted}
                            onChange={this.handleCheck}
                            className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                          />
                          <span className="ml-2 text-sm font-semibold text-blueGray-600">
                            I agree with the{' '}
                            <a
                              href="/TermsAndConditions"
                              className="text-lightBlue-500"
                              onClick={(e) => this.openInNewTab(e, '/TermsAndConditions')}
                            >
                              Terms and Conditions
                            </a>
                          </span>
                        </label>
                      </div>
                      <div className="text-center mt-6">
                        {!this.state.isRegistering ? (
                          <button
                            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            type="submit"
                          >
                            Create Account
                          </button>
                        ) : (
                          <button
                            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            disabled
                            >
                            Verifying Details...
                          </button>
                        )}

                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2">
                    <a
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      className="text-blueGray-200"
                    >
                      <small></small>
                    </a>
                  </div>
                  <div className="w-1/2 text-right">
                    <Link to="/auth" className="text-blueGray-200">
                      <strong>Sign in instead?</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
