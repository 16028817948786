export const regions = [
  { value: '1', label: 'North Scotland' },
  { value: '2', label: 'South Scotland' },
  { value: '3', label: 'North West England' },
  { value: '4', label: 'North East England' },
  { value: '5', label: 'Yorkshire' },
  { value: '6', label: 'North Wales' },
  { value: '7', label: 'South Wales' },
  { value: '8', label: 'West Midlands' },
  { value: '9', label: 'East Midlands' },
  { value: '10', label: 'East England' },
  { value: '11', label: 'South West England' },
  { value: '12', label: 'South England' },
  { value: '13', label: 'London' },
  { value: '14', label: 'South East England' },
  { value: '15', label: 'England' },
  { value: '16', label: 'Scotland' },
  { value: '17', label: 'Wales' },
]
export const ventilationTypes = [
  { value: 'None', label: 'None' },
  { value: 'Central AHUs', label: 'Central AHUs' },
  { value: 'Mixed mode', label: 'Mixed mode' },
  { value: 'Natural', label: 'Natural' },
  { value: 'Local mechanical', label: 'Local mechanical' },
  { value: 'Other', label: 'Other' },
  { value: '', label: '' },
]
export const heatingTypes = [
  { value: 'None', label: 'None' },
  { value: 'Gas boiler and fan coil units / heated ventilation', label: 'Gas boiler and fan coil units / heated ventilation' },
  { value: 'Gas boiler and radiators', label: ' Gas boiler and radiators' },
  { value: 'Gas boiler and UFH', label: 'Gas boiler and UFH' },
  { value: 'Electric (electric UFH /panel)', label: 'Electric (electric UFH /panel)' },
  { value: 'VRF', label: 'VRF' },
  { value: 'ASHPs', label: 'ASHPs' },
  { value: 'Other', label: 'Other' },
]

export const hotWaterSources = [
  { value: 'None', label: 'None' },
  { value: ' Elec point of use', label: ' Elec point of use' },
  { value: 'Indirect gas', label: 'Indirect gas' },
  { value: 'Localised gas fired', label: 'Localised gas fired' },
  { value: 'ASHPs with elec top up', label: 'ASHPs with elec top up' },
  { value: 'Centralised direct gas fired', label: 'Centralised direct gas fired' },
  { value: 'Other', label: 'Other' },

]
export const coolingSources = [
  { value: 'None', label: 'None' },
  { value: 'Central chillers', label: 'Central chillers' },
  { value: 'VRF', label: 'VRF' },
  { value: 'Local DX', label: 'Local DX' },
  { value: 'ASHP', label: 'ASHP' },
  { value: 'GSHP', label: 'GSHP' },
  { value: 'Other', label: 'Other' },

]
export const renewables = [
  { value: 'None', label: 'None' },
  { value: 'Solar PV', label: 'Solar PV' },
  { value: 'Solar thermal', label: 'Solar thermal' },
  { value: 'Biomass', label: 'Biomass' },
  { value: 'Hydrogen', label: 'Hydrogen' },
  { value: 'Wind', label: 'Wind' },
  { value: 'Other', label: 'Other' },
]

export const thermalStorages = [
  { value: 'None', label: 'None' },
  { value: 'Yes', label: 'Yes' }
]
export const onSiteRenewables = [
  { value: 'None', label: 'None' },
  { value: 'Solar PV', label: 'Solar PV' },
  { value: 'Solar thermal', label: 'Solar thermal' },
  { value: 'Biomass', label: 'Biomass' },
  { value: 'Hydrogen', label: 'Hydrogen' },
  { value: 'Wind', label: 'Wind' },
  { value: 'Other', label: 'Other' },
]
export const electricVehicleCharging = [
  { value: 'None', label: 'None' },
  { value: 'Yes', label: 'Yes' }
]
export const businessSectorTypes = [
  { value: 'Commercial/public sector ', label: 'Commercial/public sector ' },
  { value: 'Industrial', label: 'Industrial' },
]
export const buildingType = [
  { value: 'Office', label: 'Office' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Leisure', label: 'Leisure' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Schools/higher education', label: 'Schools/higher education' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Logistics/Distribution', label: 'Logistics/Distribution' },
  { value: 'Data center', label: 'Data center' },
  { value: 'Other industrial', label: 'Other industrial' },
  { value: 'Other commercial', label: 'Other commercial' },
]
export const tenureType = [
  { value: 'Rented (whole of building)', label: 'Rented (whole of building)' },
  { value: 'Renter (part of building)', label: 'Renter (part of building)' },
  { value: 'Owner occupier', label: 'Owner occupier' },
  { value: 'Other', label: 'Other' },
]

































