import { Link } from 'react-router-dom'
import 'toolcool-range-slider';

export default function LandingContent() {
    return (
        <>
            <div className="hidden md:flex   container mx-auto items-center flex flex-wrap padding-top: 100px">
                <div className="w-full md:w-10/12 lg:w-10/12 xl:w-10/12 px-4 pt-0">
                    <div className="pt-15">
                        <h2 className="font-semibold text-4xl text-blueGray-600">
                            What is the carbon footprint of your business?
                        </h2>
                        <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                            Generate a
                            <text className="font-bold text-lg text-blueGray-600"> FREE REPORT </text>
                            which provides your business with its
                            carbon footprint based on the local real-time emissions of the
                            electricity it has consumed over every half hour for the past
                            year.
                        </p>
                        <div className="mt-12">
                            <Link
                                to="/Instructions"
                                className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                Get started
                            </Link>
                            <Link
                                to="/sampleReport"
                                className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                Sample Report
                            </Link>

                        </div>
                        <div className="mt-12">
                        </div>
                        <div class="w-1/4 md:w-1/4 lg:w-1/4">
                            <div class="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                                <div class="flex-auto p-3">
                                    <div class="flex flex-wrap">
                                        <div class="relative w-1/4 pr-4 flex-grow flex-1">
                                            <h5 class="text-blueGray-600 uppercase font-bold text-lg pt-2 pb-4">
                                                View live emissions or scroll back 24hrs
                                                {'   '}
                                                <span id="sliderLiveBadge" className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-100 bg-red-500 uppercase last:mr-0 mr-1">
                                                    LIVE
                                                </span>
                                            </h5>
                                            <span id="sliderDateTime" className="text-lg font-semibold inline-block py-2 px-4 uppercase rounded-full text-blueGray-600 bg-blueGray-200 uppercase last:mr-0 mr-10">
                                                September 30, 2022 at 6:00 PM
                                            </span>

                                            <br />
                                            <br />
                                            <toolcool-range-slider class="text-blueGray-600 uppercase font-bold text-lg" id="slider1"
                                                slider-height="0.7rem"
                                                slider-width="50rem"
                                                keyboard-disabled="true"
                                                generate-labels="true"
                                                pointer1-width="30px"
                                                pointer1-height="30px"
                                                pointer1-radius="100%"
                                            >
                                            </toolcool-range-slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}