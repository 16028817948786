import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faFile } from '@fortawesome/free-solid-svg-icons'

export class CardCarbonFootPrint extends React.Component {
    numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    render() {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full py-6   shadow-lg rounded">
                    <div className="p-4 flex-auto">
                        <div className="relative h-350-px">
                            <div className="text-center mt-5">
                                <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-600 ">  Carbon Footprint  </h3>
                            </div>
                            <div className="mb-8 text-blueGray-600 text-xl font-bold  mt-5"> Local grid real time carbon footprint</div>
                            <div className="mb-8 text-blueGray-600 text-3xl font-semibold mt-5"> <FontAwesomeIcon icon={faChartLine} color="#255b45" />  {this.numberWithCommas(this.props.realtimeFootprint)}  <text className="mb-8 text-blueGray-600 text-xl font-semibold mt-5"> KgCo2e</text> </div>
                            <div className="mb-8 text-blueGray-600 text-xl font-bold mt-5"> Grid average carbon footprint</div>
                            <div className="mb-8 text-blueGray-600 text-3xl font-semibold mt-5"> <FontAwesomeIcon icon={faFile} color="#5b253b" /> {this.numberWithCommas(this.props.beisFootprint)} <text className="mb-8 text-blueGray-600 text-xl font-semibold mt-5"> KgCo2e</text> </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
