import React from 'react'
// import businessImage from '../../assets/img/Business.jpg'
// components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import DeleteReportModal from '../Modal/DeleteReportModal';

export class CardDetails extends React.Component {
  numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-1">
          <div className="px-6">
            <div className="text-center mt-5">
              <h3 className="text-xl font-bold leading-normal mb-2 text-blueGray-700 mb-2">
                Business Info
              </h3>
            </div>
            <div className="text-xl  mt-5">
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 w-6/12 text-blueGray-600 "> <FontAwesomeIcon icon={faArrowCircleRight} /> <text className="font-bold "> Business:</text>  {' '} {this.props.businessName}  </div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4  w-6/12 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} /> <text className="font-bold ">  Building name:</text> {' '} {this.props.buildingName}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Year building was completed:</text> {' '} {this.props.CompletionYear}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} /> <text className="font-bold ">  Building type:</text> {' '} {this.props.buildingType}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Building post code:</text> {' '} {this.props.buildingPostCode}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Address building:</text> {' '} {this.props.address} </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Number of floors:</text> {' '}  {this.props.numberOfFloors}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600">  <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Area of floors:</text> {' '}  {this.props.areaOfBuilding}  {' m2'} </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600">  <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> EPC rating:</text> {' '}  {this.props.epcRating}  </div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600">  <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> DEC rating:</text> {' '}  {this.props.decRating}  </div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Tenure type:</text> {' '} {this.props.tenureType}</div>
                </div>
                <div className="w-full px-4 flex-1">
                </div>
              </div>

              <hr className="my-4 md:min-w-full" />

              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Ventilation source:</text> {' '} {this.props.ventilationSource}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Ventilation source capacity:</text> {' '} {this.props.mainVentilationSourceCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Heating source:</text> {' '} {this.props.heatingSource}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} /> <text className="font-bold ">  Heating source capacity:</text> {' '} {this.props.mainHeatingSourceCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Hot water source:</text> {' '} {this.props.domesticHotWaterSource}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Hot water source capacity:</text> {' '} {this.props.mainDomesticHotWaterSourceCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Cooling source:</text> {' '} {this.props.CoolingSource}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Cooling source capacity:</text> {' '} {this.props.mainCoolingSourceCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Thermal Storage:</text> {' '} {this.props.hasThermalStorage}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Thermal Storage Capacity:</text> {' '} {this.props.thermalStorageCapacity} {' L'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> On Site Renewables:</text> {' '} {this.props.onSiteRenewables}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> On Site Renewables Capacity:</text> {' '} {this.props.onSiteRenewablesCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Electric Vehicle Charging:</text> {' '} {this.props.hasElectricVehicleCharging}</div>
                </div>
                <div className="w-full px-4 flex-1">
                  <div className="mb-4 text-blueGray-600"> <FontAwesomeIcon icon={faArrowCircleRight} />  <text className="font-bold "> Electric Vehicle Charging Capacity:</text> {' '} {this.props.hasElectricVehicleChargingCapacity} {' kW'}</div>
                </div>
              </div>
              <div className="flex flex-wrap">
                <div className="w-full px-4 flex-1">
                  <DeleteReportModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
