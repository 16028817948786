import IMG_INSTRUCTIONS from '../../assets/img/Instructions.png'
import IMG_DATAFORMATS from '../../assets/img/DataFormat.jpg'
import IMG_SAMPLEREPORT from '../../assets/img/SampleReport.JPG'
import { Link } from 'react-router-dom'

export default function Thumbnail() {
    return (
        <>
            <div className="container mx-auto">
                <div className="justify-center flex flex-wrap">
                    <div className="w-full lg:w-12/12 px-4  -mt-10">
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-4/12 px-4">
                                <h5 className="text-xl font-semibold pb-4 text-center">
                                    Instructions
                                </h5>
                                <Link to="/instructions">
                                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                        <img
                                            alt="..."
                                            className="align-middle border-none max-w-full h-auto rounded-lg"
                                            src={IMG_INSTRUCTIONS}
                                        />
                                    </div>
                                </Link>
                            </div>

                            <div className="w-full lg:w-4/12 px-4">
                                <h5 className="text-xl font-semibold pb-4 text-center">
                                    Data Formats
                                </h5>
                                <Link to="/dataFormats">
                                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                        <img
                                            alt="..."
                                            className="align-middle border-none max-w-full h-auto rounded-lg"
                                            src={IMG_DATAFORMATS}
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div className="w-full lg:w-4/12 px-4">
                                <h5 className="text-xl font-semibold pb-4 text-center">
                                    Sample Report
                                </h5>
                                <Link to="/sampleReport">
                                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-2 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                        <img
                                            alt="..."
                                            className="align-middle border-none max-w-full h-1000 rounded-lg"
                                            src={IMG_SAMPLEREPORT}
                                        />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}