import CarbonGraphIndex from '../../componenets/Cards/CarbonGraphIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'

export default function GraphContent(props) {
    return (
        <>
            <div className="container mx-auto px-6 pb-32 pt-30" id={props.id}>
                <div className="items-center flex flex-wrap">
                    <div className="w-full md:w-5/12 ml-auto px-16 md:px-8">
                        <div className="md:pr-12">
                            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-1 mb-6 shadow-lg rounded-full bg-white">
                                <FontAwesomeIcon icon={faChartArea} />
                            </div>
                            <h3 className="text-3xl font-semibold">
                                Monthly local real-time grid emissions vs national average
                            </h3>
                            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                If you are already accounting for the carbon that you use from electricity (scope 2), you could be <text className="font-bold text-lg text-blueGray-600">over or under reporting</text> it by up to <text className="font-bold text-lg text-blueGray-600">75%.</text>
                            </p>
                            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                Local real-time carbon accounting is the only way to produce accurate reporting for the electricity that you use from the grid. This approach is validated and encouraged by the Greenhouse Gas Protocol and the UK's mandatory reporting mechanism, the SECR (if you have renewables on site).
                            </p>
                            <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                                However, the ability to make the complex calculations required is challenging and many organisations only use simplified reporting techniques. Your CO2 now enables every organisation to have access to the tools and data to make use of real-time local carbon accounting.
                            </p>
                        </div>
                    </div>

                    <div className="w-full md:w-6/12 mr-auto px-0 pt-24 md:pt-0">
                        <CarbonGraphIndex />
                    </div>
                </div>
            </div>

        </>
    )
}