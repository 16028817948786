import 'toolcool-range-slider';

export default function LandingContentSliderMobile() {
    return (
        <>
            <div class="md:hidden w-full md:w-1/2 lg:w-2/4">
                <div class="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 xl:mb-0 shadow-lg">
                    <div class="flex-auto p-3">
                        <div class="flex flex-wrap">
                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                <h5 class="text-blueGray-600 uppercase font-bold text-md pt-2 pb-4">
                                    View live emissions or scroll back 24hrs
                                    {' '}
                                    <span id="sliderLiveBadgeM" className="text-sm font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-100 bg-red-500 uppercase last:mr-0 mr-1">
                                        LIVE
                                    </span>
                                </h5>
                                <span id="sliderDateTimeM" className="text-lg font-semibold inline-block py-2 px-4 uppercase rounded-full text-blueGray-600 bg-blueGray-200 uppercase last:mr-0 mr-10">
                                    September 30, 2022 at 6:00 PM
                                </span>
                                <br />
                                <br />

                                <toolcool-range-slider class="text-blueGray-600 uppercase font-bold text-lg" id="sliderM"
                                    slider-height="0.7rem"
                                    slider-width="30rem"
                                    keyboard-disabled="true"
                                    generate-labels="true"
                                    pointer1-width="30px"
                                    pointer1-height="30px"
                                    pointer1-radius="100%"
                                >
                                </toolcool-range-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}