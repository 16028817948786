import React from 'react'
import { Helmet } from 'react-helmet'

import '../assets/styles/tailwind.css'

import FAQ from '../componenets/Sections/FAQ'
import About from '../componenets/Sections/About'
import GraphContent from '../componenets/Sections/GraphContent'
import StaticLogos from '../componenets/Sections/StaticLogos'
import LandingContent from '../componenets/Sections/LandingContent'
import Thumbnail from '../componenets/Sections/Thumbnail'
import IndexNavbar from '../componenets/NavBar/IndexNavbar'
import Footer from '../componenets/Footers/Footer'
import LandingContentMobile from '../componenets/Sections/LandingContentMobile';
import LandingContentSliderMobile from '../componenets/Sections/LandingContentSliderMobile';

export default function Index() {
  return (
    <>
      <Helmet>
        <script src="./mapdata.js"></script>
        <script src="./ukmap.js"></script>
      </Helmet>

      <IndexNavbar fixed />

      <section className="header relative  items-center flex h-screen " id="ScrollTop">
        <div className="container items-center flex flex-wrap">
          <div
            className="container items-center flex flex-wrap"
            id="map"
          >
          </div>
          <LandingContentSliderMobile />
        </div>
        <LandingContent />
      </section>
      <section className="mt-1 md:mt-1 pb-1 relative bg-white-100">
        <LandingContentMobile />
      </section>

      <section className="mt-1 md:mt-1 pb-1 relative bg-blueGray-100">
        <br />
        <GraphContent id="RegionalExample" />
        <About id="About" />
        <br />
        <Thumbnail />
        <FAQ id="FAQ" />
        <br />
        <StaticLogos />
        <br />
        <br />
        <br />
      </section>
      <Footer />
    </>
  )
}
