import React from 'react'
import Sidebar from '../componenets/Sidebar/Sidebar'
import NavBar from '../componenets/NavBar/AdminNavbar'

import FooterAdmin from '../componenets/Footers/FooterAdmin'
import { IsAdmin } from '../shared/LoacalStorage'
import HeaderBar from '../componenets/Headers/HeaderBar'
import { UpdatePassword } from '../componenets/UserManagement/UpdatePassword'
import DeleteAccount from '../componenets/UserManagement/DeleteAccount'

export class UserSettings extends React.Component {
  render() {
    return (
      <>
        <Sidebar isAdmin={IsAdmin()} />

        <div className="relative md:ml-64 bg-blueGray-100">
          <NavBar PageName="User Settings" />
          <HeaderBar />

          <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <div className="flex flex-wrap">
              <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
                <UpdatePassword />
              </div>
              <div className="w-full xl:w-4/12 mb-12 xl:mb-0 px-4">
                <DeleteAccount />
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin />
      </>
    )
  }
}
