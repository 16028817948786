import React from 'react'

import Footer from '../../componenets/Footers/Footer'
import IMAGE from '../../assets/img/DataFormat.jpg'
import Navbar from '../../componenets/NavBar/AuthNavbar'

export default function DataFormatPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleSampleReportFile = async (e, type, filename) => {
    e.preventDefault();
    await fetch(`https://youco2api.azurewebsites.net/api/Business/sample?mode=${type}`,)
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  return (
    <>
      <Navbar transparent />
      <div className="absolute top-0 w-full h-full bg-center bg-cover">
        <span
          className="w-full h-full absolute  bg-blueGray-800"
        >
        </span>
      </div>
      <br /> <br />  <br />  <br /><br /> <br />   <br /> <br />
      <div className="container py-40 mx-auto px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
          <div className="mt-10 py-10 text-center">
            <div className="flex flex-wrap justify-center">
              <img alt="..." className="w-full lg:w-12/12 " src={IMAGE} />
            </div>
            <button className="bg-lightBlue-500 text-white active:bg-  lightBlue-400 font-bold uppercase text-md px-6 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={event => handleSampleReportFile(event, 'list', 'Single column - data upload template.xlsx')}
            >
              Download single column template
            </button>
            <button className="bg-lightBlue-500 text-white active:bg-lightBlue-400 font-bold uppercase text-md px-6 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={event => handleSampleReportFile(event, 'details', '48 column - data upload template.xlsx')}>
              Download 48 column template
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}