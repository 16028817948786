import React from 'react'

import Footer from '../../componenets/Footers/Footer'
import IMAGE from '../../assets/img/SampleReport_Detail.JPG'
import Navbar from '../../componenets/NavBar/AuthNavbar'

export default function SampleReportPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Navbar transparent />
      <div className="absolute top-0 w-full h-full bg-center bg-cover">
        <span
          className="w-full h-full absolute  bg-blueGray-800"
        >
        </span>
      </div>
      <br /> <br />  <br />  <br /><br /> <br />   <br /> <br />
      <div className="container py-40 mx-auto px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
          <div className="mt-10 py-10 text-center">
            <div className="flex flex-wrap justify-center">
              <img alt="..." className="w-full lg:w-12/12 " src={IMAGE} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
