import React from 'react'
import { useNavigate } from 'react-router-dom'

import Footer from '../../componenets/Footers/Footer'
import INSTRUCTIONS from '../../assets/img/Instructions.png'
import Navbar from '../../componenets/NavBar/AuthNavbar'

export default function InstructionsPage() {
  const navigate = useNavigate()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Navbar transparent />
      <div className="absolute top-0 w-full h-full bg-center bg-cover">
        <span
          className="w-full h-full absolute  bg-blueGray-800"
        >
        </span>
      </div>
      <br /> <br />  <br />  <br /><br /> <br />   <br /> <br />
      <div className="container py-40 mx-auto px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
          <div className="mt-10 py-10 text-center">
            <div className="flex flex-wrap justify-center">
              <img alt="..." className="w-full lg:w-12/12 " src={INSTRUCTIONS} />
            </div>
            <button className="bg-lightBlue-500 text-white active:bg-lightBlue-400 font-bold uppercase text-md px-6 py-6 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              onClick={() => navigate('/Auth/Register')}
            >
              Create Account Now
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
