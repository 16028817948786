import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getStorage } from '../../shared/LoacalStorage'
import CarbonGraph from './CarbonGraph'
import CarbonBarChart from './CarbonBarChart'

import { CardDetails } from './CardDetails'
import { CardBuilingCarbonFootPrint } from './CardBuilingCarbonFootPrint'
import { CardCarbonFootPrint } from './CardCarbonFootPrint'

// components
export class CardTable extends React.Component {
  static color = 'light'
  constructor(props) {
    super(props)
    this.state = { report: [], reportList: [], loading: true }
    this.state = {
      businessName: '....',
      buildingName: '....',
      buildingPostCode: '....',
      CompletionYear: '....',
      buildingType: '....',
      ventilationSource: '....',
      heatingSource: '....',
      domesticHotWaterSource: '....',
      CoolingSource: '....',
      numberOfFloors: '....',
      areaOfBuilding: '....',
      address: '....',
      beisFootprint: '....',
      realtimeFootprint: '....',
      realtimeFootprintPerM2: '....',
      beisFootprintPerM2: '....',
      tenureType: '....',
      epcRating: '....',
      decRating: '....',
      mainVentilationSourceCapacity: '....',
      mainHeatingSourceCapacity: '....',
      mainDomesticHotWaterSourceCapacity: '....',
      mainCoolingSourceCapacity: '....',
      hasThermalStorage: '....',
      thermalStorageCapacity:  '....',
      onSiteRenewables:  '....',
      onSiteRenewablesCapacity:  '....',
      hasElectricVehicleCharging:  '....',
      hasElectricVehicleChargingCapacity:  '....',
    }
    this.state = { reportLoaded: false }
  }
  componentDidMount() {
    this.populateTableData()
  }
  populateTableData = async () => {
    let token = getStorage('token')
    const response = await fetch(
      'https://youco2api.azurewebsites.net/api/Business/reports-list',
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    const data = await response.json()
    this.setState({
      reportList: data.result,
      loading: false,
    })
  }
  populateBusinessInfo = () => {
    if (this.state.report && this.state.report.result != null) {
      this.setState({
        reportLoaded: true,
        businessName: this.state.report.result.businessInfo.businessName,
        buildingName: this.state.report.result.businessInfo.buildingName,
        buildingPostCode: this.state.report.result.businessInfo.postCode,
        CompletionYear: this.state.report.result.businessInfo.yearBuildingWasCompleted,
        buildingType: this.state.report.result.businessInfo.buildingType,
        ventilationSource: this.state.report.result.businessInfo.mainVentilationSource,
        heatingSource: this.state.report.result.businessInfo.mainHeatingSource,
        domesticHotWaterSource: this.state.report.result.businessInfo.mainDomesticHotWaterSource,
        CoolingSource: this.state.report.result.businessInfo.mainCoolingSource,
        numberOfFloors: this.state.report.result.businessInfo.numberOfFloors,
        areaOfBuilding: this.state.report.result.businessInfo.areaOfBuilding,
        address: this.state.report.result.businessInfo.address,
        tenureType: this.state.report.result.businessInfo.tenureType,
        epcRating: this.state.report.result.businessInfo.epcRating,
        decRating: this.state.report.result.businessInfo.decRating,
        mainVentilationSourceCapacity: this.state.report.result.businessInfo.mainVentilationSourceCapacity,
        mainHeatingSourceCapacity: this.state.report.result.businessInfo.mainHeatingSourceCapacity,
        mainDomesticHotWaterSourceCapacity: this.state.report.result.businessInfo.mainDomesticHotWaterSourceCapacity,
        mainCoolingSourceCapacity: this.state.report.result.businessInfo.mainCoolingSourceCapacity,
        hasThermalStorage: this.state.report.result.businessInfo.hasThermalStorage,
        thermalStorageCapacity: this.state.report.result.businessInfo.thermalStorageCapacity,
        onSiteRenewables: this.state.report.result.businessInfo.onSiteRenewables,
        onSiteRenewablesCapacity: this.state.report.result.businessInfo.onSiteRenewablesCapacity,
        hasElectricVehicleCharging: this.state.report.result.businessInfo.hasElectricVehicleCharging,
        hasElectricVehicleChargingCapacity: this.state.report.result.businessInfo.hasElectricVehicleChargingCapacity,
        beisFootprint: this.state.report.result.beisFootprint.toFixed(2),
        realtimeFootprint: this.state.report.result.realtimeFootprint.toFixed(2),
        realtimeFootprintPerM2: this.state.report.result.realtimeFootprintPerM2.toFixed(2),
        beisFootprintPerM2: this.state.report.result.beisFootprintPerM2.toFixed(2)
      })
    }
  }
  populateGraphData = async (reportId) => {
    let token = getStorage('token')
    const response = await fetch(
      `https://youco2api.azurewebsites.net/api/Business/reports-admin?reportId=${reportId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    const data = await response.json()
    this.setState(
      {
        report: data,
        loading: false,
      },
      () => {
        this.populateBusinessInfo()
      },
    )
    // }
  }
  handleEdit = async (reportId) => {
    this.populateGraphData(reportId)
  }
  handleDelete = async (reportId) => {
    let token = getStorage('token')
    const body = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    await fetch(
      `https://youco2api.azurewebsites.net/api/Business/delete-report?reportId=${reportId}`,
      body,
    )
    this.populateTableData()
  }
  handleDownload = async (reportId) => {
    let token = getStorage('token')
    const body = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    await fetch(
      `https://youco2api.azurewebsites.net/api/Business/download?reportId=${reportId}`, body  
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `User Business Data.xlsx`)
        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  reportReportList(reportList) {
    console.log(reportList)
    return (
      <>
        {reportList?.map((report) => (
          <tr key={report.reportId}>
            <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
              <span
                className={
                  'ml-3 font-bold ' +
                  +(this.color === 'light' ? 'text-blueGray-600' : 'text-white')
                }
              >
                {report.business}
              </span>
            </th>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
              {report.building}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
              {report.buildingType}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
              {report.region}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
              {report.yearBuildingWasCompleted}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-lg whitespace-nowrap p-4">
              <button
                id={report.reportId}
                type="button"
                onClick={() => this.handleEdit(report.reportId)}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              {'    '}
              <button
                id={report.reportId}
                type="button"
                onClick={() => this.handleDownload(report.reportId)}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faDownload} />
              </button>
              {'    '}
              <button
                id={report.reportId}
                type="button"
                onClick={() => this.handleDelete(report.reportId)}
                className="btn btn-primary"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </td>
          </tr>
        ))}
      </>
    )
  }

  render() {
    return (
      <>
        <div
          className={
            'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
            (this.color === 'light'
              ? 'bg-white'
              : 'bg-lightBlue-900 text-white')
          }
        >
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3
                  className={
                    'font-semibold text-lg ' +
                    (this.color === 'light'
                      ? 'text-blueGray-700'
                      : 'text-white')
                  }
                >
                  User Reports
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto">
            {/* Projects table */}
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Business
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Building Name
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Building Type
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Region
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Buidling Completion YEar
                  </th>
                  <th
                    className={
                      'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                      (this.color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                    }
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{this.reportReportList(this.state.reportList)}</tbody>
            </table>
          </div>
        </div>
        <div className="w-full xl:w-8/12 mb-19 xl:mb-0 px-4">
          <CarbonGraph reportData={this.state.report} />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardCarbonFootPrint
            realtimeFootprint={this.state.realtimeFootprint}
            beisFootprint={this.state.beisFootprint}
          />
        </div>
        <div className="w-full xl:w-8/12 mb-19 xl:mb-0 px-4">
          <CarbonBarChart reportData={this.state.report} />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardBuilingCarbonFootPrint
            realtimeFootprintPerM2={this.state.realtimeFootprintPerM2}
            beisFootprintPerM2={this.state.beisFootprintPerM2}
          />
        </div>

        <div className="w-full xl:w-8/12 px-4">
          <CardDetails
            businessName={this.state.businessName}
            buildingName={this.state.buildingName}
            buildingPostCode={this.state.buildingPostCode}
            CompletionYear={this.state.CompletionYear}
            buildingType={this.state.buildingType}
            ventilationSource={this.state.ventilationSource}
            heatingSource={this.state.heatingSource}
            domesticHotWaterSource={this.state.domesticHotWaterSource}
            CoolingSource={this.state.CoolingSource}
            numberOfFloors={this.state.numberOfFloors}
            areaOfBuilding={this.state.areaOfBuilding}
            address={this.state.address}
            realtimeFootprint={this.state.realtimeFootprint}
            beisFootprint={this.state.beisFootprint}
            realtimeFootprintPerM2={this.state.realtimeFootprintPerM2}
            beisFootprintPerM2={this.state.beisFootprintPerM2}
            tenureType={this.state.tenureType}
            epcRating={this.state.epcRating}
            decRating={this.state.decRating}
            mainVentilationSourceCapacity={this.state.mainVentilationSourceCapacity}
            mainHeatingSourceCapacity={this.state.mainHeatingSourceCapacity}
            mainDomesticHotWaterSourceCapacity={this.state.mainDomesticHotWaterSourceCapacity}
            mainCoolingSourceCapacity={this.state.mainCoolingSourceCapacity}
            hasThermalStorage={this.state.hasThermalStorage}
            thermalStorageCapacity={this.state.thermalStorageCapacity}
            onSiteRenewables={this.state.onSiteRenewables}
            onSiteRenewablesCapacity={this.state.onSiteRenewablesCapacity}
            hasElectricVehicleCharging={this.state.hasElectricVehicleCharging}
            hasElectricVehicleChargingCapacity={this.state.hasElectricVehicleChargingCapacity}
          />
        </div>
      </>
    )
  }
}
