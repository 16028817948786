import { Link } from 'react-router-dom'

export default function LandingContentMobile() {
    return (
        <>
            <div className="md:hidden container mx-auto items-center flex flex-wrap  mb-12">
                <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4 ">
                    <h2 className="font-semibold text-4xl text-blueGray-600">
                        What is the carbon footprint of your business?
                    </h2>
                    <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                        Generate a
                        <text className="font-bold text-lg text-blueGray-600"> FREE REPORT </text>
                        which provides your business with its
                        carbon footprint based on the local real-time emissions of the
                        electricity it has consumed over every half hour for the past
                        year.
                    </p>
                    <div className="mt-12">
                        <Link
                            to="/Instructions"
                            className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            Get started
                        </Link>
                        <Link
                            to="/sampleReport"
                            className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            Sample Report
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}