import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faChartLine, faFile } from '@fortawesome/free-solid-svg-icons'

export class CardBuilingCarbonFootPrint extends React.Component {
    numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }
    render() {
        console.log(this.props.realtimeFootprintPerM2)
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full py-4 shadow-lg rounded">
                    <div className="p-4 flex-auto">
                        <div className="relative h-350-px">
                            <div className="text-center mt-5">
                                <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-600 ">  Building Carbon Intensity  </h3>
                            </div>
                            <div className="mb-8 text-blueGray-600 text-xl font-bold  mt-5"> Local grid real time carbon footprint per m2</div>
                            <div className="mb-8 text-blueGray-600 text-3xl font-semibold mt-5">
                                <FontAwesomeIcon icon={faChartLine} color="#255b45" />
                                {'  '}
                                <FontAwesomeIcon icon={faBuilding} />
                                {'  '}
                                {this.numberWithCommas(this.props.realtimeFootprintPerM2)} <text className="mb-8 text-blueGray-600 text-xl font-semibold mt-5"> KgCo2e/m2</text>

                            </div>
                            <div className="mb-8 text-blueGray-600 text-xl font-bold mt-5"> Grid average carbon footprint  per m2</div>
                            <div className="mb-8 text-blueGray-600 text-3xl font-semibold mt-5">
                                <FontAwesomeIcon icon={faFile} color="#5b253b" />
                                {'  '}
                                <FontAwesomeIcon icon={faBuilding} />
                                {'  '}
                                {this.numberWithCommas(this.props.beisFootprintPerM2)} <text className="mb-8 text-blueGray-600 text-xl font-semibold mt-5"> KgCo2e/m2</text>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
