export default function About(props) {
    return (
        <>
            <div className="justify-center text-center flex flex-wrap mt-14" id={props.id}>
                <div className="w-full md:w-6/12 px-12 md:px-4">
                    <h2 className="font-semibold text-4xl">About</h2>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        The carbon emissions from the electricity that your business uses is likely to be the largest contributor to your business's carbon footprint.
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        To properly understand the carbon content of the electricity that you use, you need to understand the carbon content of the electricity that is being generated in real-time. This is because the carbon content of electricity changes significantly throughout the day, the week and at different times of the year. This is predominantly due to the variation in the amount of sun and wind that is available to renewable energy generators to convert these natural resources into electricity.
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        Your business's electrical consumption also changes depending on different amounts of heating, cooling, lighting and working patterns throughout the day, the weekend, and the winter and summer.
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
                        The result of the changing carbon content of electricity and your business using more or less electricity at certain times means that calculating the carbon footprint of your business's electricity use by using an average carbon intensity can provide a misleading and incorrect output. Having accuracy when accounting for your carbon footprint is absolutely key to delivering your business’s Net Zero ambitions.
                    </p>
                    <h3 className="text-3xl font-semibold">
                        See how much difference there is when you account in real-time.
                    </h3>
                </div>
            </div>

        </>
    )
}