import React from 'react'
import Sidebar from '../../componenets/Sidebar/Sidebar'
import NavBar from '../../componenets/NavBar/AdminNavbar'
import FooterAdmin from '../../componenets/Footers/FooterAdmin'
import { getStorage, IsAdmin } from '../../shared/LoacalStorage'
import HeaderBar from '../../componenets/Headers/HeaderBar'
import CarbonBarChart from '../../componenets/Cards/CarbonBarChart'
import { CardBuilingCarbonFootPrint } from '../../componenets/Cards/CardBuilingCarbonFootPrint'
import { CardDetails } from '../../componenets/Cards/CardDetails'
import CarbonGraph from '../../componenets/Cards/CarbonGraph'
import { CardCarbonFootPrint } from '../../componenets/Cards/CardCarbonFootPrint'
import { baseURL } from '../../shared/AppConfig'

export class ViewReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = { report: [], loading: true }
    this.state = {
      businessName: '....',
      buildingName: '....',
      buildingPostCode: '....',
      CompletionYear: '....',
      buildingType: '....',
      ventilationSource: '....',
      heatingSource: '....',
      domesticHotWaterSource: '....',
      CoolingSource: '....',
      numberOfFloors: '....',
      areaOfBuilding: '....',
      address: '....',
      beisFootprint: '....',
      realtimeFootprint: '....',
      realtimeFootprintPerM2: '....',
      beisFootprintPerM2: '....',
      tenureType: '....',
      epcRating: '....',
      decRating: '....',
      mainVentilationSourceCapacity: '....',
      mainHeatingSourceCapacity: '....',
      mainDomesticHotWaterSourceCapacity: '....',
      mainCoolingSourceCapacity: '....',
      hasThermalStorage: '....',
      thermalStorageCapacity: '....',
      onSiteRenewables: '....',
      onSiteRenewablesCapacity: '....',
      hasElectricVehicleCharging: '....',
      hasElectricVehicleChargingCapacity: '....',
    }
  }
  componentDidMount() {
    this.populateGraphData()
  }
  populateBusinessInfo = () => {
    if (this.state.report && this.state.report.result != null) {
      this.setState({
        reportLoaded: true,
        businessName: this.state.report.result.businessInfo.businessName,
        buildingName: this.state.report.result.businessInfo.buildingName,
        buildingPostCode: this.state.report.result.businessInfo.postCode,
        CompletionYear: this.state.report.result.businessInfo.yearBuildingWasCompleted,
        buildingType: this.state.report.result.businessInfo.buildingType,
        ventilationSource: this.state.report.result.businessInfo.mainVentilationSource,
        heatingSource: this.state.report.result.businessInfo.mainHeatingSource,
        domesticHotWaterSource: this.state.report.result.businessInfo.mainDomesticHotWaterSource,
        CoolingSource: this.state.report.result.businessInfo.mainCoolingSource,
        numberOfFloors: this.state.report.result.businessInfo.numberOfFloors,
        areaOfBuilding: this.state.report.result.businessInfo.areaOfBuilding,
        address: this.state.report.result.businessInfo.address,
        tenureType: this.state.report.result.businessInfo.tenureType,
        epcRating: this.state.report.result.businessInfo.epcRating,
        decRating: this.state.report.result.businessInfo.decRating,
        mainVentilationSourceCapacity: this.state.report.result.businessInfo.mainVentilationSourceCapacity,
        mainHeatingSourceCapacity: this.state.report.result.businessInfo.mainHeatingSourceCapacity,
        mainDomesticHotWaterSourceCapacity: this.state.report.result.businessInfo.mainDomesticHotWaterSourceCapacity,
        mainCoolingSourceCapacity: this.state.report.result.businessInfo.mainCoolingSourceCapacity,
        hasThermalStorage: this.state.report.result.businessInfo.hasThermalStorage,
        thermalStorageCapacity: this.state.report.result.businessInfo.thermalStorageCapacity,
        onSiteRenewables: this.state.report.result.businessInfo.onSiteRenewables,
        onSiteRenewablesCapacity: this.state.report.result.businessInfo.onSiteRenewablesCapacity,
        hasElectricVehicleCharging: this.state.report.result.businessInfo.hasElectricVehicleCharging,
        hasElectricVehicleChargingCapacity: this.state.report.result.businessInfo.hasElectricVehicleChargingCapacity,
        beisFootprint: this.state.report.result.beisFootprint.toFixed(2),
        realtimeFootprint: this.state.report.result.realtimeFootprint.toFixed(2),
        realtimeFootprintPerM2: this.state.report.result.realtimeFootprintPerM2.toFixed(2),
        beisFootprintPerM2: this.state.report.result.beisFootprintPerM2.toFixed(2)
      })
    }
  }
  populateGraphData = async () => {
    let token = getStorage('token')
    const id = new URLSearchParams(window.location.search).get('id');

    const response = await fetch(
      `${baseURL()}/api/Business/reports-admin?reportId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    const data = await response.json()
    this.setState(
      {
        report: data,
        loading: false,
      },
      () => {
        this.populateBusinessInfo()
      },
    )
  }

  render() {
    return (
      <>
        <Sidebar isAdmin={IsAdmin()} />
        <div className="relative md:ml-64 bg-blueGray-100">
          <NavBar PageName="Add New Report" />
          <HeaderBar />
          <div className="px-4 md:px-10 mx-auto w-full -m-24">

            <div className="flex flex-wrap">
              <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                <CarbonGraph reportData={this.state.report} />
              </div>
              <div className="w-full xl:w-4/12 px-4">
                <CardCarbonFootPrint
                  realtimeFootprint={this.state.realtimeFootprint}
                  beisFootprint={this.state.beisFootprint}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                <CarbonBarChart reportData={this.state.report} />
              </div>
              <div className="w-full xl:w-4/12 px-4">
                <CardBuilingCarbonFootPrint
                  realtimeFootprintPerM2={this.state.realtimeFootprintPerM2}
                  beisFootprintPerM2={this.state.beisFootprintPerM2}
                />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                <CardDetails
                  businessName={this.state.businessName}
                  buildingName={this.state.buildingName} F
                  buildingPostCode={this.state.buildingPostCode}
                  CompletionYear={this.state.CompletionYear}
                  buildingType={this.state.buildingType}
                  ventilationSource={this.state.ventilationSource}
                  heatingSource={this.state.heatingSource}
                  domesticHotWaterSource={this.state.domesticHotWaterSource}
                  CoolingSource={this.state.CoolingSource}
                  numberOfFloors={this.state.numberOfFloors}
                  areaOfBuilding={this.state.areaOfBuilding}
                  address={this.state.address}
                  tenureType={this.state.tenureType}
                  epcRating={this.state.epcRating}
                  decRating={this.state.decRating}
                  mainVentilationSourceCapacity={this.state.mainVentilationSourceCapacity}
                  mainHeatingSourceCapacity={this.state.mainHeatingSourceCapacity}
                  mainDomesticHotWaterSourceCapacity={this.state.mainDomesticHotWaterSourceCapacity}
                  mainCoolingSourceCapacity={this.state.mainCoolingSourceCapacity}
                  hasThermalStorage={this.state.hasThermalStorage}
                  thermalStorageCapacity={this.state.thermalStorageCapacity}
                  onSiteRenewables={this.state.onSiteRenewables}
                  onSiteRenewablesCapacity={this.state.onSiteRenewablesCapacity}
                  hasElectricVehicleCharging={this.state.hasElectricVehicleCharging}
                  hasElectricVehicleChargingCapacity={this.state.hasElectricVehicleChargingCapacity}
                />
              </div>
            </div>
          </div>
        </div>
        <FooterAdmin />
      </>
    )
  }
}
