import React from 'react'
export default function DeleteReportModal() {
    function handleDelete() {
        window.location.href = '/ContactUs'
    }

    return (
        <>
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                <div className="rounded-t bg-white mb-0 px-6 py-6">
                    <div className="text-center flex justify-between">
                        <h6 className="text-blueGray-700 text-xl font-bold">
                            Delete Account
                        </h6>
                    </div>
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                        <br />
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-full px-4">
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                        htmlFor="grid-password"
                                    >
                                        Note: this action is Irreversible {' '}
                                        <span style={{ color: 'red', justifyContent: 'center' }}>
                                            {' '}
                                            *
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3">

                                <button
                                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleDelete()}
                                >
                                    Request Delete
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
