import React from 'react'
import { getStorage } from '../../shared/LoacalStorage'
import Alert from '../Alerts/Alert'

export class UpdatePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = { displayAlert: false, AlertMessage: '' }
        this.state = { CurrentPassword: '', NewPassword: '', ReNewPassword: '' }
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }
    renderAlert = () => {
        if (this.state.displayAlert) {
            return (<><Alert message={this.state.AlertMessage} /> </>)
        }
    }
    handleSubmission = async (event) => {
        this.setState({ displayAlert: false })
        const { CurrentPassword, NewPassword, ReNewPassword } = this.state
        event.preventDefault()
        if (NewPassword !== ReNewPassword) {
            this.setState({ displayAlert: true, AlertMessage: "Password does not match." })
        }
        else {
            let token = getStorage('token')
            const body = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    currentPassword: CurrentPassword,
                    newPassword: NewPassword,
                    confirmPassword: ReNewPassword
                }),
            }

            const response = await fetch(
                'https://youco2api.azurewebsites.net/api/Authentication/change-password',
                body,
            )
            const data = await response.json()
            // when response is sucess
            if (data.success === true) {
                window.location.href = '/Auth'
            } else if (data.success === false) {
                this.setState({ displayAlert: true, AlertMessage: data.errors[0] })
            }
        }
    }
    render() {
        return (
            <>
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                    <div className="rounded-t bg-white mb-0 px-6 py-6">
                        <div className="text-center flex justify-between">
                            <h6 className="text-blueGray-700 text-xl font-bold">
                                Update Password
                            </h6>
                        </div>
                    </div>
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <form onSubmit={this.handleSubmission}>
                            {this.renderAlert()}
                            <br />
                            <div className="flex flex-wrap">
                                <div className="w-full lg:w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Current Password{' '}
                                            <span style={{ color: 'red', justifyContent: 'center' }}>
                                                {' '}
                                                *
                                            </span>
                                        </label>
                                        <input
                                            required
                                            name="CurrentPassword"
                                            type="password"
                                            value={this.state.CurrentPassword}
                                            onChange={this.handleChange}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            New Password{' '}
                                            <span style={{ color: 'red', justifyContent: 'center' }}>
                                                {' '}
                                                *
                                            </span>
                                        </label>
                                        <input
                                            required
                                            name="NewPassword"
                                            type="password"
                                            value={this.state.NewPassword}
                                            onChange={this.handleChange}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        />
                                    </div>
                                </div>
                                <div className="w-full lg:w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Re-enter New Password{' '}
                                            <span style={{ color: 'red', justifyContent: 'center' }}>
                                                {' '}
                                                *
                                            </span>
                                        </label>
                                        <input
                                            required
                                            name="ReNewPassword"
                                            type="password"
                                            value={this.state.ReNewPassword}
                                            onChange={this.handleChange}
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-12/12 px-4">
                                <div className="relative w-full mb-3">

                                    <button
                                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="submit"
                                    >
                                        Update Password
                                    </button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
