import React from 'react'
import Chart from 'chart.js'

export default function CarbonGraph(reportData) {
  React.useEffect(() => {
    let labelsList = []
    let valuesList = []
    let staticFactorList = []
    let yearBuildingWasCompleted = ''
    if (reportData.reportData && reportData.reportData.result != null) {
      labelsList = reportData.reportData.result.graph.labels
      valuesList = reportData.reportData.result.graph.values
      staticFactorList = reportData.reportData.result.graph.staticFactors
      yearBuildingWasCompleted = reportData.reportData.result.businessInfo.reportYear
    }
    var config = {
      type: 'line',
      data: {
        labels: labelsList,
        datasets: [
          {
            label: 'Local real time grid carbon footprint',
            backgroundColor: '#4c51bf',
            borderColor: '#4c51bf',
            data: valuesList,
            fill: false,
          },
          {
            label: 'Grid average carbon footprint',
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            data: staticFactorList,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: 'Sales Charts',
          fontColor: 'white',
        },
        legend: {
          labels: {
            fontColor: 'white',
          },
          align: 'end',
          position: 'bottom',
        },
        // tooltips: {
        //   mode: 'index',
        //   intersect: false,
        // },
        // hover: {
        //   mode: 'nearest',
        //   intersect: false,
        // },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: yearBuildingWasCompleted,
                fontColor: 'white',
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: 'rgba(33, 37, 41, 0.3)',
                zeroLineColor: 'rgba(0, 0, 0, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'KgCo2e',
                fontColor: 'white',
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: 'rgba(255, 255, 255, 0.15)',
                zeroLineColor: 'rgba(33, 37, 41, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    }
    var ctx = document.getElementById('line-chart').getContext('2d')
    window.myLine = new Chart(ctx, config)
  }, [reportData])
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Overview
              </h6>
              <h2 className="text-white text-xl font-semibold">
                Local real time grid carbon footprint vs grid average carbon footprint
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas id="line-chart"></canvas>
          </div>
        </div>
      </div>
    </>
  )
}
