import React from 'react'
// import './index.css';
import * as ReactDOMClient from 'react-dom/client'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

// import App from './App';
import reportWebVitals from './reportWebVitals'
import Index from './views/Index'
import { Login } from './componenets/Auth/Login'
import { Register } from './componenets/Auth/Register'
import { Dashboard } from './views/Dashboard'
import { UserSettings } from './views/UserSettings'
import { AdminPage } from './views/AdminPage'
import { ContactUs } from './views/ContactUs'
import TermsPage from './views/Static Page/TermsPage'
import InstructionsPage from './views/Static Page/InstructionsPage'
import DataFormatPage from './views/Static Page/DataFormatPage'

import SampleReportPage from './views/Static Page/SampleReportPage'
import { Verification } from './componenets/Auth/Verification'
import { ForgotPassword } from './componenets/Auth/ForgotPassword'
import { ResetPassword } from './componenets/Auth/ResetPassword'
import PrivacyPolicyPage from './views/Static Page/PrivacyPolicyPage'
import { Report } from './views/Reports/Report'
import { AddReport } from './views/Reports/AddReport'
import { ViewReport } from './views/Reports/ViewReport'

const root = ReactDOMClient.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />}></Route>
      <Route path="/auth" element={<Login />}></Route>
      <Route path="/auth/register" element={<Register />}></Route>
      <Route path="/auth/verification" element={<Verification />}></Route>
      <Route path="/Dashboard" element={<Dashboard />}></Route>
      <Route path="/AdminPage" element={<AdminPage />}></Route>
      <Route path="/ContactUs" element={<ContactUs />}></Route>
      <Route path="/TermsAndConditions" element={<TermsPage />}></Route>
      <Route path="/PrivacyPolicy" element={<PrivacyPolicyPage />}></Route>
      <Route path="/Instructions" element={<InstructionsPage />}></Route>
      <Route path="/DataFormats" element={<DataFormatPage />}></Route>
      <Route path="/SampleReport" element={<SampleReportPage />}></Route>
      <Route path="/UserSettings" element={<UserSettings />}></Route>
      <Route path="/auth/forgotPassword" element={<ForgotPassword />}></Route>
      <Route path="/auth/resetPassword" element={<ResetPassword />}></Route>

      <Route path="/Report" element={<Report />}></Route>
      <Route path="/Report/Add" element={<AddReport />}></Route>
      <Route path="/Report/View" element={<ViewReport />}></Route>

    </Routes>
  </BrowserRouter>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
