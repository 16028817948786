import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../NavBar/AuthNavbar'
import Alert from '../Alerts/Alert'

export class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = { password: '', confirmPassword: '' }
    this.state = { displayAlert: false, AlertMessage: '' }
  }
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return ('');
  }
  handleSubmission = async (event) => {
    this.setState({ displayAlert: false })
    const { password, confirmPassword } = this.state
    event.preventDefault()
    if (password !== confirmPassword) {
      this.setState({ displayAlert: true, AlertMessage: "Password does not match." })
    }
    else {
      const body = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          newPassword: password,
          confirmPassword: confirmPassword,
          userId: this.getQueryVariable('id'),
          token: this.getQueryVariable('token'),
        }),
      }

      const response = await fetch(
        'https://youco2api.azurewebsites.net/api/Authentication/reset-password',
        body,
      )
      const data = await response.json()
      // when response is sucess
      if (data.success === true) {
        window.location.href = '/Auth'
      } else if (data.success === false) {
        this.setState({ displayAlert: true, AlertMessage: data.errors[0] })
      }
    }
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  renderAlert = () => {
    if (this.state.displayAlert) {
      return (
        <>
          <Alert message={this.state.AlertMessage} />
        </>
      )
    }
  }

  render() {
    return (
      <>
        <Navbar transparent />
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          ></div>
          <div className="container mx-auto px-5 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-5">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-blueGray-500 text-sm font-bold">
                        Reset Password
                      </h6>
                    </div>

                    <hr className="mt-6 border-b-1 border-blueGray-300" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-blueGray-400 text-center mb-3 font-bold">
                      <small>Please enter your new credentials</small>
                    </div>
                    <form onSubmit={this.handleSubmission}>
                      {this.renderAlert()}

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          New Password
                        </label>
                        <input
                          required
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="New Password"
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Confirm Password
                        </label>
                        <input
                          required
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Confirm Password"
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                          type="submit"
                        >
                          Reset
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2">
                  </div>
                  <div className="w-1/2 text-right">
                    <Link to="/auth" className="text-blueGray-200">
                      <strong>Sign In</strong>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
