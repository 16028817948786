import React from 'react'
import { clearDraft, getStorage, IsAdmin, saveDraft } from '../../shared/LoacalStorage'
import Alert from '../Alerts/Alert'
import axios from 'axios'
import Select from 'react-select'
import { buildingType, coolingSources, electricVehicleCharging, heatingTypes, hotWaterSources, onSiteRenewables, regions, tenureType, thermalStorages, ventilationTypes } from '../../shared/DropDownCache'
import { Link } from 'react-router-dom'

export class AddReportCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayAlert: false,
      success: true,
      AlertMessage: '',
      GeneratingReport: true,
    }
    this.state = {
      BusinessName: '',
      BuildingName: '',
      BuildingPostCode: '',
      RegionId: '',
      RegionName: '',
      BuildingType: '',
      TenureType: '',
      YearBuildingWasCompleted: '',
      NumberOfFloors: '',
      AreaOfBuilding: '',
      EPCRating: '',
      DECRating: '',
      Address: '',
      MainVentilationSource: '',
      MainVentilationSourceCapacity: '',
      MainHeatingSource: '',
      MainHeatingSourceCapacity: '',
      DomesticHotWaterSource: '',
      DomesticHotWaterSourceCapacity: '',
      CoolingSource: '',
      CoolingSourceCapacity: '',
      ThermalStorage: '',
      ThermalStorageCapacity: '',
      OnSiteRenewables: '',
      OnSiteRenewablesCapacity: '',
      ElectricVehicleCharging: '',
      ElectricVehicleChargingCapacity: '',
      StartDate: '2020-01-01',
      EndDate: '2020-12-30',
      selectedFile: null,
      isDraft: false
    }
  }
  componentDidMount() {
    this.setState({ GeneratingReport: false })
    this.restoreDraft()
  }
  restoreDraft = async () => {
    let draft = getStorage('draft');
    if (draft) {
      draft = JSON.parse(draft)
      this.setState({
        BusinessName: draft.BusinessName,
        BuildingName: draft.BuildingName,
        BuildingPostCode: draft.BuildingPostCode,
        RegionId: draft.RegionId,
        RegionName: draft.RegionName,
        BuildingType: draft.BuildingType,
        TenureType: draft.TenureType,
        YearBuildingWasCompleted: draft.YearBuildingWasCompleted,
        NumberOfFloors: draft.NumberOfFloors,
        AreaOfBuilding: draft.AreaOfBuilding,
        EPCRating: draft.EPCRating,
        DECRating: draft.DECRating,
        Address: draft.Address,
        MainVentilationSource: draft.MainVentilationSource,
        MainVentilationSourceCapacity: draft.MainVentilationSourceCapacity,
        MainHeatingSource: draft.MainHeatingSource,
        MainHeatingSourceCapacity: draft.MainHeatingSourceCapacity,
        DomesticHotWaterSource: draft.DomesticHotWaterSource,
        DomesticHotWaterSourceCapacity: draft.DomesticHotWaterSourceCapacity,
        CoolingSource: draft.CoolingSource,
        CoolingSourceCapacity: draft.CoolingSourceCapacity,
        ThermalStorage: draft.ThermalStorage,
        ThermalStorageCapacity: draft.ThermalStorageCapacity,
        OnSiteRenewables: draft.OnSiteRenewables,
        OnSiteRenewablesCapacity: draft.OnSiteRenewablesCapacity,
        ElectricVehicleCharging: draft.ElectricVehicleCharging,
        ElectricVehicleChargingCapacity: draft.ElectricVehicleChargingCapacity,
      })
    }
  }
  handleSubmission = async (event) => {
    event.preventDefault()
    this.setState({ displayAlert: false, GeneratingReport: true })
    const {
      BusinessName,
      BuildingName,
      BuildingPostCode,
      RegionId,
      RegionName,
      BuildingType,
      TenureType,
      YearBuildingWasCompleted,
      NumberOfFloors,
      AreaOfBuilding,
      EPCRating,
      DECRating,
      Address,
      MainVentilationSource,
      MainVentilationSourceCapacity,
      MainHeatingSource,
      MainHeatingSourceCapacity,
      DomesticHotWaterSource,
      DomesticHotWaterSourceCapacity,
      CoolingSource,
      CoolingSourceCapacity,
      ThermalStorage,
      ThermalStorageCapacity,
      OnSiteRenewables,
      OnSiteRenewablesCapacity,
      ElectricVehicleCharging,
      ElectricVehicleChargingCapacity,
      StartDate,
      EndDate,
      selectedFile,
      isDraft
    } = this.state
    if (isDraft) {
      const data = {
        BusinessName,
        BuildingName,
        BuildingPostCode,
        RegionId,
        RegionName,
        BuildingType,
        TenureType,
        YearBuildingWasCompleted,
        NumberOfFloors,
        AreaOfBuilding,
        EPCRating,
        DECRating,
        Address,
        MainVentilationSource,
        MainVentilationSourceCapacity,
        MainHeatingSource,
        MainHeatingSourceCapacity,
        DomesticHotWaterSource,
        DomesticHotWaterSourceCapacity,
        CoolingSource,
        CoolingSourceCapacity,
        ThermalStorage,
        ThermalStorageCapacity,
        OnSiteRenewables,
        OnSiteRenewablesCapacity,
        ElectricVehicleCharging,
        ElectricVehicleChargingCapacity,
        StartDate,
        EndDate,
        selectedFile,
      };
      saveDraft('draft', data);
      this.setState({ displayAlert: true, AlertMessage: "Report has been saved successfully as draft.", success: true })
    }
    else {
      let token = getStorage('token')
      let formData = new FormData()
      formData.append('fromFile', selectedFile.name)
      formData.append('file', selectedFile)
      try {
        let url = `https://youco2api.azurewebsites.net/api/Business/add`
        const resp = await axios.post(url, formData, {
          params: {
            businessName: BusinessName,
            buildingName: BuildingName,
            postCode: BuildingPostCode,
            buildingType: BuildingType,
            tenureType: TenureType,
            yearBuildingWasCompleted: YearBuildingWasCompleted,
            numberOfFloors: NumberOfFloors,
            areaOfBuilding: AreaOfBuilding,
            epcRating: EPCRating,
            decRating: DECRating,
            address: Address,
            regionId: RegionId,
            MainVentilationSource: MainVentilationSource,
            MainVentilationSourceCapacity: MainVentilationSourceCapacity,
            MainHeatingSource: MainHeatingSource,
            MainHeatingSourceCapacity: MainHeatingSourceCapacity,
            MainDomesticHotWaterSource: DomesticHotWaterSource,
            MainDomesticHotWaterSourceCapacity: DomesticHotWaterSourceCapacity,
            MainCoolingSource: CoolingSource,
            MainCoolingSourceCapacity: CoolingSourceCapacity,
            HasThermalStorage: ThermalStorage,
            ThermalStorageCapacity: ThermalStorageCapacity,
            OnSiteRenewables: OnSiteRenewables,
            OnSiteRenewablesCapacity: OnSiteRenewablesCapacity,
            HasElectricVehicleCharging: ElectricVehicleCharging,
            HasElectricVehicleChargingCapacity: ElectricVehicleChargingCapacity,
            startDate: StartDate,
            endDate: EndDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (resp.data.success === true) {
          this.setState({ displayAlert: true, AlertMessage: "Report generated sucessfully.", success: true, })
          clearDraft();
          this.props.onSelect(event)
          this.props.UpdateReportStatus(event)
        } else if (resp.data.success === false) {
          this.setState({ displayAlert: true, AlertMessage: resp.data.errors[0], success: false, })
        }
      } catch (e) {
        console.log(e)
      }
    }
    this.setState({ GeneratingReport: false })
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleFileChange = (e) => {
    this.setState({ ...this.state, selectedFile: e.target.files[0] })
  }
  handleSingleColumnFileDownload = async (e) => {
    e.preventDefault();
    await fetch(
      `https://youco2api.azurewebsites.net/api/Business/sample?mode=list`,
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Single column - data upload template.xlsx`)
        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  handleMultiColumnFileDownload = async (e) => {
    e.preventDefault();
    await fetch(
      `https://youco2api.azurewebsites.net/api/Business/sample?mode=details`,
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `48 column - data upload template.xlsx`)
        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
  }
  renderAlert = () => {
    if (this.state.displayAlert) {
      return (
        <>
          <Alert message={this.state.AlertMessage} success={this.state.success} />
        </>
      )
    }
  }
  handleRegion = (selectedOption) => {
    this.setState({ RegionId: selectedOption.value })
  }
  render() {
    return (
      <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Add Report
              </h6>
              {IsAdmin() &&
                <Link
                  to="/Report"
                  className="bg-emerald-500 text-white active:bg-emerald-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                  View Reports
                </Link>
              }
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form onSubmit={this.handleSubmission}>
              <br />
              {this.renderAlert()}
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Business Information
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Business name{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="BusinessName"
                      value={this.state.BusinessName}
                      onChange={this.handleChange}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Building name
                    </label>
                    <input
                      name="BuildingName"
                      value={this.state.BuildingName}
                      onChange={this.handleChange}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Year building was completed{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="YearBuildingWasCompleted"
                      value={this.state.YearBuildingWasCompleted}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Region
                    </label>
                    <Select
                      options={regions}
                      value={{ value: this.state.RegionId, label: this.state.RegionName }}
                      onChange={(selectedOption) => {
                        this.setState({ RegionId: selectedOption.value, RegionName: selectedOption.label })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Building type / main function{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={buildingType}
                      value={{ value: this.state.BuildingType, label: this.state.BuildingType }}
                      onChange={(selectedOption) => {
                        this.setState({ BuildingType: selectedOption.value })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Tenure Type
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={tenureType}
                      value={{ value: this.state.TenureType, label: this.state.TenureType }}
                      onChange={(selectedOption) => {
                        this.setState({ TenureType: selectedOption.value })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Building post code{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="BuildingPostCode"
                      value={this.state.BuildingPostCode}
                      onChange={this.handleChange}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />

                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Address
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *{' '}
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="Address"
                      value={this.state.Address}
                      onChange={this.handleChange}
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Total floor area/area your business occupies-m2 {' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="AreaOfBuilding"
                      value={this.state.AreaOfBuilding}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      EPC rating
                    </label>
                    <input
                      name="EPCRating"
                      value={this.state.EPCRating}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      DEC rating (if relevant)
                    </label>
                    <input
                      name="DECRating"
                      value={this.state.DECRating}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Number of floors/floors your business occupies{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <input
                      required={!this.state.isDraft}
                      name="NumberOfFloors"
                      value={this.state.NumberOfFloors}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Main ventilation source{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={ventilationTypes}
                      value={{ value: this.state.MainVentilationSource, label: this.state.MainVentilationSource }}
                      onChange={(selectedOption) => {
                        this.setState({
                          MainVentilationSource: selectedOption.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="MainVentilationSourceCapacity"
                      value={this.state.MainVentilationSourceCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Main heating source{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={heatingTypes}
                      value={{ value: this.state.MainHeatingSource, label: this.state.MainHeatingSource }}
                      onChange={(selectedOption) => {
                        this.setState({
                          MainHeatingSource: selectedOption.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="MainHeatingSourceCapacity"
                      value={this.state.MainHeatingSourceCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Main domestic hot water source{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={hotWaterSources}
                      value={{ value: this.state.DomesticHotWaterSource, label: this.state.DomesticHotWaterSource }}
                      onChange={(selectedOption) => {
                        this.setState({
                          DomesticHotWaterSource: selectedOption.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="DomesticHotWaterSourceCapacity"
                      value={this.state.DomesticHotWaterSourceCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Main cooling source{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={coolingSources}
                      value={{ value: this.state.CoolingSource, label: this.state.CoolingSource }}
                      onChange={(selectedOption) => {
                        this.setState({ CoolingSource: selectedOption.value })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="CoolingSourceCapacity"
                      value={this.state.CoolingSourceCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Does the building have Thermal storage{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={thermalStorages}
                      value={{ value: this.state.ThermalStorage, label: this.state.ThermalStorage }}
                      onChange={(selectedOption) => {
                        this.setState({ ThermalStorage: selectedOption.value })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-L
                    </label>
                    <input
                      name="ThermalStorageCapacity"
                      value={this.state.ThermalStorageCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Does the building have on-site renewables{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={onSiteRenewables}
                      value={{ value: this.state.OnSiteRenewables, label: this.state.OnSiteRenewables }}
                      onChange={(selectedOption) => {
                        this.setState({
                          OnSiteRenewables: selectedOption.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="OnSiteRenewablesCapacity"
                      value={this.state.OnSiteRenewablesCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Does the building have electric vehicle charging{' '}
                      <span style={{ color: 'red', justifyContent: 'center' }}>
                        {' '}
                        *
                      </span>
                    </label>
                    <Select
                      options={electricVehicleCharging}
                      value={{ value: this.state.ElectricVehicleCharging, label: this.state.ElectricVehicleCharging }}
                      onChange={(selectedOption) => {
                        this.setState({
                          ElectricVehicleCharging: selectedOption.value,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-3/12 px-4">
                  <div className="relative w-full mb-3">
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Installed capacity-KW
                    </label>
                    <input
                      name="ElectricVehicleChargingCapacity"
                      value={this.state.ElectricVehicleChargingCapacity}
                      onChange={this.handleChange}
                      type="Number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-1">
                <div className="px-6">
                  <div className=" mt-5">
                    <h3 className="text-xl font-bold leading-normal mb-2 text-blueGray-700 mb-2 ">
                      ELECTRICITY DATA {'      '}
                      <button className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={event => window.location.href = '/dataFormats'}>
                        View accepted data format
                      </button>
                    </h3>
                  </div>

                  <div className="relative w-full mb-3">
                    <input
                      required={!this.state.isDraft}
                      onChange={this.handleFileChange}
                      type="file"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-800 bg-blueGray-200 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="px-6">
                  <button className="bg-lightBlue-500 text-white active:bg-lightBlue-400 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    onClick={this.handleSingleColumnFileDownload} >
                    Download single column template
                  </button>
                  <button className="bg-lightBlue-500 text-white active:bg-lightBlue-400 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    onClick={this.handleMultiColumnFileDownload} >
                    Download 48 column template
                  </button>
                </div>
                <br />
              </div>
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  {!this.state.GeneratingReport ? (
                    <>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-md px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={() => this.setState({ isDraft: false })}
                        type="submit"
                      >
                        Generate
                      </button>
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-md px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        onClick={() => this.setState({ isDraft: true })}
                        type="submit"
                      >
                        Save as draft
                      </button>
                    </>
                  ) : (
                    <button className="bg-blueGray-700 text-white active:bg-lightBlue-400 font-bold uppercase text-md px-6 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" disabled>
                      Generating...
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  }
}
