export default function FAQ(props) {
    return (
        <>
            <div className="justify-center text-center flex flex-wrap mt-14" id={props.id} >
                <div className="w-full md:w-6/12 px-12 md:px-4">
                    <h2 className="font-semibold text-4xl">FAQs</h2>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Why don't businesses already calculate their carbon footprint like this?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        Historic carbon reporting couldn't use realtime carbon factors or real-time electricity consumption data as the metering and processes were not available to collect this information.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	What has changed that now allows my business to use real-time local carbon footprinting?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        The carbon intensity of the electricity that supplies your business is now available in real time. This, coupled with changes in regulation (which now require most UK businesses to have real-time electricity metering), means that businesses can now use technology platforms like ours to process large amounts of data (35,040 data points per yearly footprint) to produce accurate real-time local carbon footprints.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Does Your CO2's approach conform with the current carbon reporting guidance?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        Yes, both the UK government and the Greenhouse Gas Protocol advocate this real time approach to carbon accounting when the data is available.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Where does the carbon intensity data come from and is it reliable?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        The real-time local carbon intensity data comes from National Grid, who operate the electrical transmission network in the UK. They are responsible for making sure that energy supply matches demand in real-time, they are therefore the most credible source of data for the real-time emissions from electricity use.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Why does your business need to change its reporting approach?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        If you are committed to, or planning to commit to, your own Net Zero target then you need to measure your business’s emissions as accurately as possible so you can measure your progress towards that target.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Why does accuracy matter?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        If you aren't accurate in your emissions reporting, it affects your efforts to deliver Net Zero. In addition, where your business offsets its emissions with payment to regulated carbon reduction schemes then you may be overpaying or underpaying for those offsets.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	I have a "green" electricity tariff isn't my electricity already zero carbon?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        Unfortunately it isn't, the electricity you use is what is available in your local area when you use it. Buying a green electricity tariff is definitely better than not having one, but it only means that you are paying to generate low carbon electricity somewhere in the UK, not necessarily where your business is located. If you really want low carbon electricity then you need to generate it yourself and you need to match its generation to your business's consumption in realtime.
                    </p>

                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left font-bold">
                        ●	Why do you want my building information to be able to see my footprint report?
                    </p>
                    <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-left">
                        Providing us with some basic information about your building allows us to better understand how different types of buildings perform. This will allow us to create a picture of how the carbon accounting gap of using the grid average as opposed to real time data is affecting the reporting of the UK’s transition towards Net Zero. We will always anonymise any output we publish and you will always have the option to remove your data.
                    </p>
                </div>
            </div>
        </>
    )
}